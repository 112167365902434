import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.grid = void 0;
var _core = _core2;
var defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512]
};
var config = {
  gridGap: {
    property: "gridGap",
    scale: "space",
    defaultScale: defaults.space
  },
  gridColumnGap: {
    property: "gridColumnGap",
    scale: "space",
    defaultScale: defaults.space
  },
  gridRowGap: {
    property: "gridRowGap",
    scale: "space",
    defaultScale: defaults.space
  },
  gridColumn: true,
  gridRow: true,
  gridAutoFlow: true,
  gridAutoColumns: true,
  gridAutoRows: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  gridTemplateAreas: true,
  gridArea: true
};
var grid = (0, _core.system)(config);
exports.grid = grid;
var _default = grid;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _grid = exports.grid;
export { _grid as grid };